import React from 'react';//lazy
import { useDispatch, useSelector } from 'react-redux';
import { setConfirmPopup, setContentPopup } from 'state/Popup';
import Popup from 'components/Popup2';
import * as S from './style';

interface Props {
  visible?: boolean;
  children?: any;
  onCancel?: any;
  onOk?: any;
  title?: any;
  footer?: boolean;
  okText?: string;
  cancelText?: string;
  cancel?: boolean;
  okCancel?: boolean;
  isCloseBtn?: boolean;
  className?: any;
  path?: string;
  width?: string;
}

function Notice(): React.ReactElement {
  const dispatch = useDispatch();
  const popup: any = useSelector((state: any) => state.popup?.popup);

  return (
    <Popup
      title='Notice'
      // cancel={false}
      visible={Boolean(popup)}
      onCancel={() => dispatch(setContentPopup(''))}
      onOk={() => dispatch(setConfirmPopup())}>
      {popup}
    </Popup>
  );
}

export default Notice;
