import { InviteMember, InviteRegister } from 'apis'

import { createSlice } from '@reduxjs/toolkit'

// import { DataGasLimitState } from '../types'

const initialState: any = { f1: '', f2: '', f3: '', listInvite: [] }

export const inviteSlice = createSlice({
  name: 'DataInvite',
  initialState,
  reducers: {
    setDataInvite: (state, action?: any) => {
      return { ...state, ...action.payload }
      // state.data = action.payload
    },
    setListInvite: (state: any, action?: any) => {
      // eslint-disable-next-line no-param-reassign
      state.listInvite = action.payload
    },
  },
})

export const { setDataInvite,setListInvite } = inviteSlice.actions

// Thunks
// eslint-disable-next-line import/prefer-default-export
export const fetchInviteMember = (data) => async () => {
  const response: any = await InviteMember(data)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const datas = response?.data?.data
}

export const fetchInviteRegister = (address?: any) => async (dispatch?: any) => {
  const response: any = await InviteRegister({ addr: address })
  const data = response?.data?.data ?? []
  // const dataConvert =[data?.f1,data?.f2,data?.f3,data?.f4,data?.f5,data?.f6,data?.f7,data?.f8,data?.f1,data?.f1,data?.f1,data?.f1,data?.f1,]
  const dataConvert = Array.from({ length: 12 }).map((v, i) => data[`f${i + 1}`])

  const filtered = dataConvert.filter((el) => {
    return el !== null && el !== undefined
  })
  dispatch(setListInvite(filtered))
  // dispatch(setDataInvite(data))
}

export default inviteSlice.reducer
