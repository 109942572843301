/* eslint-disable react/require-default-props */
import React, { useState } from "react";
import styled from "styled-components";
// import { Link } from "components/Link";
// import { HelpAltGobit } from "components/Svg";
import { Button } from "components/Button";
import { Flex } from "components/Box";
import { Modal } from "../Modal";
import WalletCard from "./WalletCard";
import config from "./config";
import { Login } from "./types";

interface Props {
  login: Login;
  onDismiss?: () => void;
}

// const HelpLink = styled(Link)`
//   display: flex;
//   align-self: center;
//   align-items: center;
//   margin-top: 24px;
// `;

const ModalConnect = styled(Modal)`
  border: solid 2px #375bd2;
  border-radius: 30px;
  background: #1b1b1b;
  max-height: 85vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
  };
  &::-webkit-scrollbar-track {
    background: #E4E6EB;
    border-radius: 0px 0px 9px 9px;
    margin: 18px

  }

  &::-webkit-scrollbar-thumb {
    background: #3A3B3C;
    border-radius: 10px;
  };

  &::-webkit-scrollbar-thumb:hover {
    background: #3A3B3C;
  };

  @media screen and (min-width: 320px) and (max-width: 720px) {
    min-width: 100%;
  }
`;

const CancelButton = styled(Button)`
  flex: 1;
  margin-right: 8px;
  border: solid 2px #375bd2;
  background-color: #303030;
  color: #375bd2;
  font-weight: 100;
`;

const ConnectButton = styled(Button)`
  flex: 1;
  margin-left: 8px;
  /* background-image: linear-gradient(to bottom,#7363f9,#5851ea); */
  font-weight: 500;
`;


const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null }) => {
  const [walletSelect, setWalletSelect] = useState<any>("");
  const [walletActive, setWalletActive] = useState<any>(-1);

  return (
    <ModalConnect title="Connect to a wallet" onDismiss={onDismiss}>
      {config.map((entry, index) => (
        <WalletCard
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          id={index}
          login={login}
          walletConfig={entry}
          onDismiss={onDismiss}
          setWalletSelect={setWalletSelect}
          walletActive={walletActive}
          setWalletActive={setWalletActive}
          mb={index < config.length - 1 ? "8px" : "0"}
        />
      ))}

      <Flex style={{ marginTop: 50 }}>
        <CancelButton onClick={() => onDismiss()}>Cancel</CancelButton>
        <ConnectButton
          variant='secondary'
          onClick={() => {
            login(walletSelect);
            onDismiss();
          }}
        >
          Connect
        </ConnectButton>
      </Flex>

      {/* <HelpLink
      href="https://docs.pancakeswap.finance/guides/faq#how-do-i-set-up-my-wallet-on-binance-smart-chain"
      external
      color="#ffffff"
    >
      <HelpAltGobit width="16px" color="#ffffff" mr="6px" />
      Learn how to connect
    </HelpLink> */}
    </ModalConnect>
  );
};

export default ConnectModal;
