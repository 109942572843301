import { LinkStatus } from './types'

export const status = {
  LIVE: <LinkStatus>{
    text: 'LIVE',
    color: 'failure',
  },
  SOON: <LinkStatus>{
    text: 'SOON',
    color: 'warning',
  },
  NEW: <LinkStatus>{
    text: 'NEW',
    color: 'success',
  },
}

export const socials = [
  {
    label: 'Telegram',
    icon: 'TelegramIcon',
    href: 'https://t.me/nftstaking',
  },
  {
    label: 'Twitter',
    icon: 'TwitterAltGobit',
    href: 'https://twitter.com/Nft_Staking',
  },
]

export const linksHorizontal = [
  {
    label: "About ",
    href: "/",
    // available: true
  },
  {
    label: "Staking",
    href: "/staking",
    // available: true
  },
  {
    label: "Whitepaper ",
    href: "/",
    // available: true
  },
  // {
  //   label: "About",
  //   href: "https://gomining.co/#intro1",
  // },
  // {
  //   label: "GO Mining",
  //   href: "https://gomining.co/#coin",
  // },
  // {
  //   label: "Roadmap",
  //   href: "https://gomining.co/#roadmap",
  // },
  // {
  //   label: "Mining",
  //   href: "/",
  // },
  // {
  //   label: "Invite",
  //   href: "/invite",
  // },
  {
    label: 'Profile',
    href: '/profile',
  },
  {
    label: 'Commission Policy',
    href: '/policy',
  },
]

export const HmSocialLink = [
  {
    label: 'HmTelegram',
    icon: 'HmTelegram',
    href: 'https://t.me/nftstaking',
  },
  {
    label: 'HmMedium',
    icon: 'HmMedium',
    href: '/',
  },
  {
    label: 'HmGit',
    icon: 'HmGit',
    href: '/',
  },
  {
    label: 'HmStorybook',
    icon: 'HmStorybook',
    href: '/',
  },
  {
    label: 'HmDiscord',
    icon: 'HmDiscord',
    href: '/',
  },
]

export const MENU_HEIGHT = 150
export const MENU_ENTRY_HEIGHT = 48
export const SIDEBAR_WIDTH_FULL = 240
export const SIDEBAR_WIDTH_REDUCED = 56
