import React, { useState, useEffect, useCallback, useMemo } from 'react'
// import throttle from 'lodash/throttle'
import { useHistory, useLocation } from 'react-router-dom'

/** Image */
import logo from 'assets/images/logo.svg'
import iconX from 'assets/images/x.png'
import { ReactComponent as MenuIcon } from 'assets/images/ham-menu.svg'
import { useAppDispatch } from 'state'
import { fetchPopup } from 'state/actions'
import UserWallet from 'components/Menu/UserWallet'
import { linksHorizontal } from 'components/Menu/config'
// import useClaim from 'hooks/useClaim'
import ConnectModal from 'components/WalletModal/ConnectModal'
import { useModal } from 'components/Modal'
import { Button } from 'components/Button'
import useClaimGSV from 'hooks/useClaimGSV'
import useWeb3 from 'hooks/useWeb3'
import { getClaimContract } from 'utils/contractHelpers'
import useGetIsClaim from 'hooks/useGetIsClaim'
import Marquee from 'react-fast-marquee'
/** Style */
import {
  ImgLogo,
  Wrapper,
  Content,
  MenuMobile,
  WrapperButton,
  WrapperMenu,
  WrapperLinkItem,
  Notice,
  // Button
} from './style'

/** Type */
import { NavProps } from './types'

const Menu: React.FC<NavProps> = ({ account, login, logout, errorr }) => {
  // const { isXl } = useMatchBreakpoints();
  const [active, setActive] = useState<boolean>(false)
  const [isNavActive, setIsNavActive] = useState(false);
  const [successClaim, setSuccessClaim] = useState(false)
  // const [refAddress, setRefAddress] = useState<any>('')
  const history = useHistory()
  const dispatch = useAppDispatch()
  const location: any = useLocation()

  const [onPresentConnectModal] = useModal(<ConnectModal login={login} />)

  // const web3 = useWeb3()

  // const contractClaim = getClaimContract(web3)
  // const getIsClaim = useGetIsClaim(contractClaim)

  // const { onClaimGSV } = useClaimGSV(contractClaim)

  // const accountEllipsis = account
  //   ? `${account.substring(0, 4)}...${account.substring(account.length - 4, account.length)}`
  //   : null

  // useEffect(() => {
  //   const callBack = async () => {
  //     const result = await getCaver();
  //     dispatch(fetchCreateUser(result[0]))
  //   };
  //   callBack()
  // }, [isConnect]);

  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsNavActive(true);
      } else {
        setIsNavActive(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once
  
  useEffect(() => {
    if (active) {
      window.document.body.style.overflow = 'hidden'
    } else {
      window.document.body.style.overflow = 'auto'
    }
  }, [active])

  // const handleClaim = useCallback(async () => {
  //   try {
  //     const tx = await onClaimGSV(0.004)
  //     if (tx.status === true) {
  //       setSuccessClaim(true)
  //     }
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }, [onClaimGSV])

  const type = useMemo(() => {
    if(location?.pathname === '/' || location?.pathname === '/policy' ){
      return 'home'
    }
    return ''
  }, [location])
  return (
    <Wrapper className={isNavActive && 'scroll'}>
      <WrapperMenu className={active && 'active'}>
        {linksHorizontal?.map((item: any, index: any) => {
          return (
            <WrapperLinkItem
              key={index}
              href={!item?.available ? `${item.href}` : `/`}
              target={item?.newTab && '_blank'}
            >
              {item?.label}
            </WrapperLinkItem>
          )
        })}
      </WrapperMenu>
      <Content>
        <a href="/">
          <ImgLogo
            src={logo}
            alt="logo"
            className={errorr === true && 'center'}
            // onClick={() => history.push('/')}
          />
        </a>

        {!errorr && (
          <>
            <div className="menu">
              {/* <p style={{ marginRight: 60 }}>예치</p> */}
              {linksHorizontal?.map((item: any, index: any) => {
                return (
                  <WrapperLinkItem
                    key={index}
                    href={!item?.available ? `${item.href}` : `/`}
                    target={item?.newTab && '_blank'}
                    className={type}
                  >
                    {item?.label}
                  </WrapperLinkItem>
                )
              })}
            </div>
            <WrapperButton>
              {/* {!getIsClaim && !successClaim && (
                <Button
                  full
                  style={{ marginRight: 10 }}
                  variant="secondary"
                  // disabled={pendingClaim}
                  onClick={handleClaim}
                >
                  Claim
                </Button>
              )} */}

              <UserWallet account={account} login={login} logout={logout} />
              {/* <ButtonOutline onClick={() => setIsConnect(!isConnect)}>{account ? accountEllipsis : 'Connect Wallet'}</ButtonOutline> */}
              <MenuMobile
                onClick={() => {
                  setActive(!active)
                  dispatch(
                    fetchPopup({
                      isPopup: false,
                    }),
                  )
                }}
              >
                {active ? <img src={iconX} alt="" /> : <MenuIcon fontSize={24} />}
              </MenuMobile>
            </WrapperButton>
          </>
        )}
      </Content>
     {/* <Marquee>
        <Notice>
        Notice of temporary suspension of profit and commission withdrawals within 72 hours to adjust to the company&apos;s development roadmap. We apologize to customers for this inconvenience.
        </Notice>
        </Marquee> */}
    </Wrapper>
  )
}

export default Menu
