import { scales, variants } from "./types";

export const scaleVariants = {
  [scales.MD]: {
    height: "48px",
    padding: "0 24px",
  },
  [scales.SM]: {
    height: "32px",
    padding: "0 16px",
  },
  [scales.XS]: {
    height: "20px",
    fontSize: "12px",
    padding: "0 8px",
  },
};

export const styleVariants = {
  [variants.PRIMARY]: {
    backgroundColor: "rgb(40 41 48)",
    color: "white",
  },
  [variants.SECONDARY]: {
    backgroundImage: 'linear-gradient(270deg,#375bd2 0%,rgb(63 58 255 / 75%) 47%,rgb(255 140 59 / 22%) 100%)',
    border: 'solid 1px #375bd2',
    padding: "14px 42px",
    color: "white",
    borderRadius: 30,
    textTransform: 'uppercase',
    ":disabled": {
      backgroundColor: "transparent",
    },
    ":hover": {
      backgroundImage: "linear-gradient(90deg, #375bd2 0%, rgb(58 84 255 / 75%) 47%, rgb(255 140 59 / 22%) 100%)",
    },
  },
  [variants.TERTIARY]: {
    backgroundColor: "tertiary",
    boxShadow: "none",
    color: "primary",
  },
  [variants.SUBTLE]: {
    backgroundColor: "textSubtle",
    color: "white",
  },
  [variants.DANGER]: {
    backgroundColor: "failure",
    color: "white",
  },
  [variants.SUCCESS]: {
    backgroundColor: "success",
    color: "white",
  },
  [variants.TEXT]: {
    backgroundColor: "transparent",
    color: "primary",
    boxShadow: "none",
  },
  [variants.CONNECTED]: {
    boxShadow: "none",
    border: "solid 3px #375bd2",
    backgroundColor: "rgba(0, 0, 0, 0.33)",
    color: "white",
  },
};
