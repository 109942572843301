/* eslint-disable react/require-default-props */
import React from "react";
import styled from "styled-components";

import Button from "components/Button/Button";
import Text from "components/Text/Text";
import Flex from "components/Box/Flex";
import { Modal } from "../Modal";
import { connectorLocalStorageKey } from "./config";

interface Props {
  account: string;
  logout: () => void;
  onDismiss?: () => void;
}

const AccModal = styled(Modal)`
  @media screen and (max-width: 624px) {
    min-width: 100%;
  }
`;

const AccountModal: React.FC<Props> = ({ account, logout, onDismiss = () => null }) => (
  <AccModal title="Your wallet" onDismiss={onDismiss}>
    <Text
      fontSize="14px"
      bold
      style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginBottom: "8px" }}
      color="#fff"
    >
      {account}
    </Text>
    {/* <Flex style={{color: '#eab676'}}>
      <LinkExternal small href={`https://bscscan.com/address/${account}`} >
        View on BscScan
      </LinkExternal>
      <CopyToClipboard  toCopy={account}>Copy Address</CopyToClipboard>
    </Flex> */}
    <Flex style={{justifyContent: 'center', marginTop: 16}} >
      <Button
        variant="secondary"
        onClick={() => {
          logout();
          window.localStorage.removeItem(connectorLocalStorageKey);
          onDismiss();
        }}
      >
        Logout
      </Button>
    </Flex>
  </AccModal>
);

export default AccountModal;
