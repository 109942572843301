import React, { useCallback } from 'react'
import { useWalletModal } from 'components/WalletModal'
import { Login } from 'components/WalletModal/types'
import { WraperUseWallet, WalletButton } from './style'

interface Props {
  // eslint-disable-next-line react/require-default-props
  account?: string
  login: Login
  logout: () => void
}

const UserWallet: React.FC<Props> = ({ account, login, logout }) => {
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(login, logout, account)

  const accountEllipsis = account
    ? `${account.substring(0, 4)}...${account.substring(account.length - 4, account.length)}`
    : null

  const handleClick = useCallback(() => {
    if (accountEllipsis) onPresentAccountModal()
    if (!accountEllipsis) onPresentConnectModal()
  }, [accountEllipsis])

  return (
    <WraperUseWallet>
      <WalletButton onClick={() => handleClick()}>
        {accountEllipsis ?? 'Connect'} 
      </WalletButton>
    </WraperUseWallet>
  )
}

export default React.memo(UserWallet, (prevProps, nextProps) => prevProps.account === nextProps.account)
