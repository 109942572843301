import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved

// import { GmarketSansTTFBold, GmarketSansTTFLight, GmarketSansTTFMedium } from 'assets/font/font'

// declare module 'styled-components' {
//   /* eslint-disable @typescript-eslint/no-empty-interface */
//   export interface DefaultTheme {}
// }

import {
  PoppinsBlack,
  PoppinsBold,
  PoppinsSemiBold,
  PoppinsLight,
  PoppinsMedium,
  PoppinsRegular,
  PoppinsThin,
  CircularStdMedium,
} from "assets/fonts/fonts";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Poppins-Thin';
    src: local('Poppins-Thin'), url(${PoppinsThin}) format('truetype');
}

@font-face {
    font-family: 'Poppins-Light';
    src: local('Poppins-Light'), url(${PoppinsLight}) format('truetype');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: local('Poppins-Regular'), url(${PoppinsRegular}) format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: local('Poppins-Medium'), url(${PoppinsMedium}) format('truetype');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: local('Poppins-SemiBold'), url(${PoppinsSemiBold}) format('truetype');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: local('Poppins-Bold'), url(${PoppinsBold}) format('truetype');
}

@font-face {
    font-family: 'Poppins-Black';
    src: local('Poppins-Black'), url(${PoppinsBlack}) format('truetype');
}

@font-face {
    font-family: 'Circular';
    src: local('CircularStdMedium'), url(${CircularStdMedium}) format('truetype');
}

  body {
    /* background-color: #0d0415; */
    color: #fff;
    flex-grow: 0;
    margin: 0px;
    font-family: Circular;

    /* background-color: #f5f9fc; */
    
    img {
      height: auto;
      max-width: 100%;
    }
  }

  *{
    /* font-family: 'OpenSans-Regular'; */
    box-sizing: border-box;
  }

`

export default GlobalStyle
